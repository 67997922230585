import styles from './index.module.less';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { ChevronLeft, ChevronRight } from '@metro/icons';
import {
  GOVList,
  ComList,
} from '@/components/Layouts/MainPage/components/partner-content/constants';
import useScreenSizeStore from '@/zustand/screenSize';
import map from 'lodash/map';
import { useSetState, useMount, useDebounceEffect } from 'ahooks';
import { useWindowSize } from 'react-use';
import { clone } from 'lodash';

function chunkArray(array: any[], size: number) {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

const PartnerContent = () => {
  const screenSize = useWindowSize();
  const isMobile = useScreenSizeStore((state) => state.isMobile());
  const [state, setState] = useSetState({
    GOVLIST: [],
    COMLIST: [],
    chunkSize: 6,
  });
  const createChunk = () => {
    const a = chunkArray(clone(GOVList), state.chunkSize);
    const b = chunkArray(clone(ComList), state.chunkSize);
    setState({
      GOVLIST: a,
      COMLIST: b,
    });
  };
  useMount(() => {
    createChunk();
  });
  useDebounceEffect(
    () => {
      createChunk();
    },
    [state.chunkSize],
    { wait: 50 },
  );
  useDebounceEffect(() => {
    if (screenSize.width < 980) {
      setState({
        chunkSize: 6,
      });
    } else if (screenSize.width < 1440) {
      setState({
        chunkSize: 8,
      });
    } else {
      setState({
        chunkSize: 10,
      });
    }
  }, [screenSize.width]);
  if (isMobile) {
    return (
      <div className={styles.mobilePartnerContent}>
        <div className={styles.title}>合作伙伴</div>
        <div className={classNames(styles.subTitle, 'mt-[24px] mb-[16px]')}>
          <div className={styles.before}></div>
          主要政府客户
          <div className={styles.after}></div>
        </div>
        <div className="flex flex-wrap justify-center gap-[12px]">
          {map(GOVList, (item) => {
            return (
              <div className={styles.item}>
                <img
                  className="w-full h-full object-contain"
                  src={item.img}
                  alt=""
                  loading="lazy"
                />
              </div>
            );
          })}
        </div>
        <div className={classNames(styles.subTitle, 'mt-[24px] mb-[16px]')}>
          <div className={styles.before}></div>
          主要企业客户
          <div className={styles.after}></div>
        </div>
        <div className="flex flex-wrap justify-center gap-[12px]">
          {map(ComList, (item) => {
            return (
              <div className={styles.item}>
                <img
                  className="w-full h-full object-contain"
                  src={item.img}
                  alt=""
                  loading="lazy"
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.partnerContent}>
      <div className={styles.title}>合作伙伴</div>
      <div className={classNames(styles.subTitle, 'mt-[60px] mb-[30px]')}>
        <div className={styles.before}></div>
        主要政府客户
        <div className={styles.after}></div>
      </div>
      <div className="h-[176px] w-[90vw] max-w-[1240px]">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          navigation={{
            enabled: true,
            prevEl: `.${styles.prev}`,
            nextEl: `.${styles.next}`,
          }}
          modules={[Navigation]}
          className={classNames(styles.mySwiper, 'relative')}
        >
          {map(state.GOVLIST, (item, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  className={classNames('grid gap-[12px] pl-[50px] pr-[50px]', {
                    'grid-cols-3': state.chunkSize === 6,
                    'grid-cols-4': state.chunkSize === 8,
                    'grid-cols-5': state.chunkSize === 10,
                  })}
                >
                  {map(item, (i, idx) => {
                    return (
                      <div
                        key={idx}
                        className="bg-white rounded-[4px] h-[80px] pl-[12px] pr-[12px] pb-[8px] pt-[8px]"
                      >
                        <img
                          className="w-full h-full object-contain"
                          src={i.img}
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    );
                  })}
                </div>
              </SwiperSlide>
            );
          })}
          <div
            className={classNames(styles.prev, 'flex items-center justify-center cursor-pointer')}
          >
            <ChevronLeft className="text-[20px]" />
          </div>
          <div
            className={classNames(styles.next, 'flex items-center justify-center cursor-pointer')}
          >
            <ChevronRight className="text-[20px]" />
          </div>
        </Swiper>
      </div>

      <div className={classNames(styles.subTitle, 'mt-[60px] mb-[30px]')}>
        <div className={styles.before}></div>
        主要企业客户
        <div className={styles.after}></div>
      </div>

      <div className="h-[176px] w-[90vw] max-w-[1240px]">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          navigation={{
            enabled: true,
            prevEl: `.${styles.prev}`,
            nextEl: `.${styles.next}`,
          }}
          modules={[Navigation]}
          className={classNames(styles.mySwiper, 'relative')}
        >
          {map(state.COMLIST, (item, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  className={classNames('grid gap-[12px] pl-[50px] pr-[50px]', {
                    'grid-cols-3': state.chunkSize === 6,
                    'grid-cols-4': state.chunkSize === 8,
                    'grid-cols-5': state.chunkSize === 10,
                  })}
                >
                  {map(item, (i, idx) => {
                    return (
                      <div
                        key={idx}
                        className="bg-white rounded-[4px] h-[80px] pl-[12px] pr-[12px] pb-[8px] pt-[8px]"
                      >
                        <img
                          className="w-full h-full object-contain"
                          src={i.img}
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    );
                  })}
                </div>
              </SwiperSlide>
            );
          })}
          <div
            className={classNames(styles.prev, 'flex items-center justify-center cursor-pointer')}
          >
            <ChevronLeft className="text-[20px]" />
          </div>
          <div
            className={classNames(styles.next, 'flex items-center justify-center cursor-pointer')}
          >
            <ChevronRight className="text-[20px]" />
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default PartnerContent;
