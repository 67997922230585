export const GOVList = [
  {
    text: '1',
    img: '/main/gov/01.png',
  },
  {
    text: '2',
    img: '/main/gov/02.png',
  },
  {
    text: '3',
    img: '/main/gov/03.png',
  },
  {
    text: '4',
    img: '/main/gov/04.png',
  },
  {
    text: '5',
    img: '/main/gov/05.png',
  },
  {
    text: '6',
    img: '/main/gov/06.png',
  },
  {
    text: '7',
    img: '/main/gov/07.png',
  },
  {
    text: '8',
    img: '/main/gov/08.png',
  },
  {
    text: '9',
    img: '/main/gov/09.png',
  },
  {
    text: '10',
    img: '/main/gov/10.png',
  },
  {
    text: '11',
    img: '/main/gov/11.png',
  },
  {
    text: '12',
    img: '/main/gov/12.png',
  },
  {
    text: '13',
    img: '/main/gov/13.png',
  },
  {
    text: '14',
    img: '/main/gov/14.png',
  },
  {
    text: '15',
    img: '/main/gov/15.png',
  },
  {
    text: '16',
    img: '/main/gov/16.png',
  },
  {
    text: '17',
    img: '/main/gov/17.png',
  },
  {
    text: '18',
    img: '/main/gov/18.png',
  },
  {
    text: '19',
    img: '/main/gov/19.png',
  },
];

export const ComList = [
  {
    text: '1',
    img: '/main/firm/01.png',
  },
  {
    text: '2',
    img: '/main/firm/02.png',
  },
  {
    text: '3',
    img: '/main/firm/03.png',
  },
  {
    text: '4',
    img: '/main/firm/04.png',
  },
  {
    text: '5',
    img: '/main/firm/05.png',
  },
  {
    text: '6',
    img: '/main/firm/06.png',
  },
  {
    text: '7',
    img: '/main/firm/07.png',
  },
  {
    text: '8',
    img: '/main/firm/08.png',
  },
  {
    text: '9',
    img: '/main/firm/09.png',
  },
  {
    text: '10',
    img: '/main/firm/10.png',
  },
  {
    text: '11',
    img: '/main/firm/11.png',
  },
  {
    text: '12',
    img: '/main/firm/12.png',
  },
  {
    text: '13',
    img: '/main/firm/13.png',
  },
  {
    text: '14',
    img: '/main/firm/14.png',
  },
  {
    text: '15',
    img: '/main/firm/15.png',
  },
  {
    text: '16',
    img: '/main/firm/16.png',
  },
];
