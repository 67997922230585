import styles from './index.module.less';
import LowCodeItem from '@/components/Layouts/MainPage/components/core-content/low-code/low-code-item';
import map from 'lodash/map';
const list = [
  {
    title: 'Datlas',
    tip: '可灵活配置的空间可视化分析平台',
    tipColor: 'yellow',
    text: '集多源数据导入、要素化配置、模块化搭建、地理联动分析、快速外链发布等功能于一体。',
    img: '/main/datlas.png',
  },
  {
    title: '数据市场',
    tip: '灵活安全的数据资源管理共享平台',
    tipColor: 'green',
    text: '集数据管理、数据共享、数据运营等功能于一体。',
    img: '/main/market.png',
  },
  {
    title: '一表通',
    tip: '可灵活配置流程和审批的低代码平台',
    tipColor: 'red',
    text: '支持根据数据共享条件自动判定、审批规则快速调整，提升复杂组织流程效率。',
    img: '/main/table.png',
  },
];
const LowCode = () => {
  return (
    <div className={styles.lowCode}>
      {map(list, (item, index) => {
        return (
          <LowCodeItem
            key={index}
            title={item.title}
            text={item.text}
            tip={item.tip}
            tipColor={item.tipColor}
            img={item.img}
          />
        );
      })}
    </div>
  );
};

export default LowCode;
