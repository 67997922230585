import Head from 'next/head';
import type { InferGetStaticPropsType } from 'next';
import { NextPageWithLayout } from './_app';
import React from 'react';
import Layout from '../layouts';
import MainPage from '@/components/Layouts/MainPage';

const Home: NextPageWithLayout<InferGetStaticPropsType<any>> = () => {
  return <MainPage />;
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>;
};

export default Home;
