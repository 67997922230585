import styles from './index.module.less';
import classNames from 'classnames';
import useScreenSizeStore from '@/zustand/screenSize';
const SupplyChain = () => {
  const isMobile = useScreenSizeStore((state) => state.isMobile());

  if (isMobile) {
    return (
      <div className={styles.mobileSupplyChain}>
        <img className={styles.img} src="/main/chain.png" alt="" />
        <div className={styles.left}>
          <div className={styles.text}>
            建立城市数据供应链，通过自研数据技术生产高价值数据资产，建立城市数据底座，提供给多行业进行数据分析、决策和运营。
          </div>
          <div className="flex items-center gap-[48px]">
            <div>
              <div className={styles.title}>2.97万 +</div>
              <div className={styles.subText}>数据包</div>
            </div>
            <div>
              <div className={classNames(styles.title, '')}>38.27亿 +</div>
              <div className={styles.subText}>数据总量</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.supplyChain}>
      <div className={styles.left}>
        <div className={styles.text}>
          建立城市数据供应链，通过自研数据技术生产高价值数据资产，建立城市数据底座，提供给多行业进行数据分析、决策和运营。
        </div>
        <div className={styles.title}>2.97万 +</div>
        <div className={styles.subText}>数据包</div>
        <div className={classNames(styles.title, 'mt-[48px]')}>38.27亿 +</div>
        <div className={styles.subText}>数据总量</div>
      </div>
      <img className={styles.img} src="/main/chain.png" alt="" />
    </div>
  );
};

export default SupplyChain;
