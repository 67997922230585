import styles from './index.module.less';
import classNames from 'classnames';
import { Tabs } from 'antd';
import { CubeFilled, DatabaseFilled, SurroundFilled } from '@metro/icons';
import SupplyChain from '@/components/Layouts/MainPage/components/core-content/supply-chain';
import LowCode from '@/components/Layouts/MainPage/components/core-content/low-code';
import useScreenSizeStore from '@/zustand/screenSize';
const CoreContent = () => {
  const isMobile = useScreenSizeStore((state) => state.isMobile());
  return (
    <div
      className={classNames(
        isMobile ? styles.mobileCoreContent : styles.coreContent,
        'flex flex-col items-center justify-center',
      )}
    >
      <div className={styles.title}>核心能力</div>
      <Tabs
        rootClassName={styles.tabs}
        centered
        items={[
          {
            key: '1',
            label: (
              <div className="flex items-center gap-[8px]">
                <DatabaseFilled className={classNames(styles.subTitleIcon, 'shrink-0')} />
                <div className={styles.subTitle}>数据供应链</div>
              </div>
            ),
            children: <SupplyChain />,
          },
          {
            key: '2',
            label: (
              <div className="flex items-center gap-[8px]">
                <SurroundFilled className={classNames(styles.subTitleIcon, 'shrink-0')} />
                <div className={styles.subTitle}>分析算法</div>
              </div>
            ),
            children: '数据分析',
          },
          {
            key: '3',
            label: (
              <div className="flex items-center gap-[8px]">
                <CubeFilled className={classNames(styles.subTitleIcon, 'shrink-0')} />
                <div className={styles.subTitle}>低代码平台</div>
              </div>
            ),
            children: <LowCode />,
          },
        ]}
      />
    </div>
  );
};

export default CoreContent;
