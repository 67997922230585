import TopImage from '@/components/Layouts/MainPage/components/top-image';
import MainProduct from '@/components/Layouts/MainPage/components/main-product';
import PartnerContent from '@/components/Layouts/MainPage/components/partner-content';
import CoreContent from '@/components/Layouts/MainPage/components/core-content';
import NewsContent from '@/components/Layouts/MainPage/components/news-content';

const MainPage = () => {
  return (
    <div>
      <TopImage />
      <MainProduct />
      <CoreContent />
      <PartnerContent />
      <NewsContent />
    </div>
  );
};

export default MainPage;
