import styles from './index.module.less';
import useScreenSizeStore from '@/zustand/screenSize';
import classNames from 'classnames';
import ProductItem from '@/components/Layouts/MainPage/components/main-product/product-item';
import map from 'lodash/map';
import { useRouter } from 'next/router';

const MainProduct = () => {
  const router = useRouter();
  const list = [
    {
      title: '脉策城市地图',
      content:
        '城市地图产品是脉策结合房企需求开发的一款空间研究产品，通过融合地图、数据和交互功能，为企业客户提供全面的城市和客户分析服务。',
      jump: () => {
        router.push('/city-map');
      },
    },
    {
      title: '脉策市民云',
      content:
        '脉策市民云面向城市治理场景，在一个通用空间底盘的基础上，打通数据壁垒，统一数据底座，实现人房数据资产的一体化管理。',
      jump: () => {
        router.push('/citizen-cloud');
      },
    },
    {
      title: 'ChatMDT',
      content:
        '始于对话，超越对话，在通往AGI的路上，您所需要的一切，都可以从这里找到。我们致力于以最合理的价钱，让您找到最适合的ai能力。',
      jump: () => {
        router.push('/chatmdt');
      },
    },
  ];
  const isMobile = useScreenSizeStore((state) => state.isMobile());
  return (
    <div className={styles.mainProduct}>
      <div className={classNames('flex flex-col items-center justify-center', styles.content)}>
        <div className={styles.title}>主要产品</div>
        <div className={styles.list}>
          {map(list, (item) => {
            return <ProductItem title={item.title} content={item.content} jump={item.jump} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default MainProduct;
