import styles from './index.module.less';
import { ChevronRight } from '@metro/icons';
import classNames from 'classnames';

interface LowCodeItemProps {
  title: string;
  tip: string;
  tipColor: string;
  text: string;
  img: string;
}
const LowCodeItem = (props: LowCodeItemProps) => {
  const { title, tip, tipColor, text, img } = props;
  return (
    <div className={styles.lowCodeItem}>
      <div className={styles.img}>
        <img
          src={img}
          draggable="false"
          className="w-full h-full object-cover"
          alt=""
          loading="lazy"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={classNames(styles.tip, styles[tipColor])}>{tip}</div>
        <div className={styles.text}>{text}</div>
        <div className={styles.more}>
          了解更多 <ChevronRight />
        </div>
      </div>
    </div>
  );
};

export default LowCodeItem;
