import styles from './index.module.less';
import useScreenSizeStore from '@/zustand/screenSize';
const TopImage = () => {
  const isMobile = useScreenSizeStore((state) => state.isMobile());
  return (
    <div className={isMobile ? styles.mobileTopImage : styles.topImage}>
      <img src="/main/top-image.png" loading="lazy" className={styles.img} alt="" />
      <div className="z-10">
        <div className={styles.title}>脉策科技</div>
        <div className={styles.subTitle}>城市科学，赋能百业</div>
        <div className={styles.text}>
          脉策科技致力于城市数字化领域，结合智库能力和数据科技，基于自主研发自主产权的低代码数据系统开发平台和领域算法，叠加行业认知和数据服务，提供多样化综合解决方案能力，为政府和大型企业提供智库咨询、数据研究、算法服务、平台产品及系统解决方案。
        </div>
      </div>
    </div>
  );
};

export default TopImage;
