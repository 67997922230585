import styles from './index.module.less';
import { RightForward } from '@metro/icons';
import map from 'lodash/map';
import useScreenSizeStore from '@/zustand/screenSize';
const list = [
  {
    img: '/main/top-image.png',
    text: '花木数字孪生城市 | “数字孪生城市”建设深入推进，这个街道指尖上的社区让服务更便利',
  },
  {
    img: '/main/top-image.png',
    text: '花木数字孪生城市 | “数字孪生城市”建设深入推进，这个街道指尖上的社区让服务更便利',
  },
  {
    img: '/main/top-image.png',
    text: '花木数字孪生城市 | “数字孪生城市”建设深入推进，这个街道指尖上的社区让服务更便利',
  },
  {
    img: '/main/top-image.png',
    text: '花木数字孪生城市 | “数字孪生城市”建设深入推进，这个街道指尖上的社区让服务更便利',
  },
];
const NewsContent = () => {
  const isMobile = useScreenSizeStore((state) => state.isMobile());
  const isTablet = useScreenSizeStore((state) => state.isTablet());
  const isDesktop = useScreenSizeStore((state) => state.isDesktop());

  return (
    <div
      className={
        isMobile
          ? styles.mobileNewsContent
          : isTablet
            ? styles.tabletNewsContent
            : styles.newsContent
      }
    >
      <div className={styles.title}>新闻动态</div>
      <div className={styles.more}>
        更多新闻 <RightForward className="text-[18px]" />
      </div>

      {isMobile && (
        <div className="flex flex-col">
          {map(list, (item, index) => {
            return (
              <div key={index} className={styles.item}>
                <img className={styles.img} src={item.img} alt="" loading="lazy" />
                <div className={styles.text}>{item.text}</div>
              </div>
            );
          })}
        </div>
      )}
      {isTablet && (
        <div className="grid grid-cols-2 gap-[20px]">
          {map(list, (item, index) => {
            return (
              <div key={index} className={styles.item}>
                <img className={styles.img} src={item.img} alt="" loading="lazy" />
                <div className={styles.text}>{item.text}</div>
              </div>
            );
          })}
        </div>
      )}
      {isDesktop && (
        <div className="flex items-center gap-[32px]">
          {map(list, (item, index) => {
            return (
              <div key={index} className={styles.item}>
                <img className={styles.img} src={item.img} alt="" loading="lazy" />
                <div className={styles.text}>{item.text}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NewsContent;
