import styles from './index.module.less';
import { RightForward } from '@metro/icons';

interface ProductItemProps {
  title: string;
  content: string;
  jump: () => void;
}
const ProductItem = (props: ProductItemProps) => {
  const { title, content, jump } = props;
  return (
    <div className={styles.productItem}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{content}</div>
      {/*<div className="flex-1"></div>*/}
      <div className={styles.more} onClick={jump}>
        了解更多 <RightForward className="text-[18px]" />
      </div>
    </div>
  );
};

export default ProductItem;
